"use client";

import { PrivyProvider } from "@privy-io/react-auth";
import { Toaster } from "react-hot-toast";

export default function LoggedInLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <PrivyProvider
      appId={process.env.NEXT_PUBLIC_PRIVY_APP_ID_CLIENT}
      clientId={process.env.NEXT_PUBLIC_PRIVY_CLIENT_ID_CLIENT}
      config={{
        captchaEnabled: true,
        embeddedWallets: {
          createOnLogin: "users-without-wallets",
          requireUserPasswordOnCreate: true,
          noPromptOnSignature: false,
        },
        loginMethods: ["wallet", "email", "google"],
        appearance: {
          showWalletLoginFirst: false,
          logo: "/images/WM-Logotype-NoBG-Basalt_2x.png",
        },
      }}
    >
      {children}
      <Toaster position="bottom-center" />
    </PrivyProvider>
  );
}
